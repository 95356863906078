<template>
  <div id="app3">

    <!-- Humberger Begin -->
    <div class="humberger__menu__overlay"></div>
    <div class="humberger__menu__wrapper">

      <div class="humberger__menu__cart">
        <ul>
          <li><a href="#"><i class="fa fa-heart"></i> <span>1</span></a></li>
          <li><a href="#"><i class="fa fa-shopping-bag"></i> <span>3</span></a></li>
        </ul>
        <div class="header__cart__price" style=""><span>$0</span></div>
      </div>
      <div class="humberger__menu__widget">
        <div style="display: none;" class="header__top__right__language">
          <img src="img/language.png" alt="">
          <div>English</div>
          <span class="arrow_carrot-down"></span>
          <ul>
            <li><a href="#">Spanis</a></li>
            <li><a href="#">English</a></li>
          </ul>
        </div>
        <div class="header__top__right__auth">
          <a href="#"><i class="fa fa-user"></i> Connexion</a>
        </div>
      </div>
      <nav class="humberger__menu__nav mobile-menu">
        <ul>
          <li class="active"><a href="./index.html"><span class="fa fa-home"></span>Acceuil</a></li>
          <li><a href="#"><span class="fa fa-shopping-bag"></span> Achat</a></li>
          <li><a href="#"><span class="fa fa-phone"></span> Contact</a></li>
        </ul>
      </nav>
      <div id="mobile-menu-wrap"></div>
      <div class="header__top__right__social">
        <a href="#"><i class="fa fa-facebook"></i></a>
        <a href="#"><i class="fa fa-whatsapp"></i></a>
      </div>
      <div class="humberger__menu__contact">
        <ul>
          <li><i class="fa fa-envelope"></i> contact@economat.com</li>
          <li><span class="fa fa-truck"></span> Livraison à la maison ou au bureau.</li>
        </ul>
      </div>
    </div>
    <!-- Humberger End -->

    <!-- Header Section Begin -->
    <header class="header">
      <div class="header__top">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="header__top__left">
                <ul>
                  <li><i class="fa fa-envelope"></i>contact@economat.com</li>
                  <li><span class="fa fa-phone-square"></span> +243810000000</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="header__top__right">
                <div class="header__top__right__social">
                  <a href="#"><i class="fa fa-facebook"></i></a>
                  <a href="#"><i class="fa fa-whatsapp"></i></a>
                </div>
                <div style="display: none;" class="header__top__right__language">
                  <img src="img/language.png" alt="">
                  <div>English</div>
                  <span class="arrow_carrot-down"></span>
                  <ul>
                    <li><a href="#">Spanis</a></li>
                    <li><a href="#">English</a></li>
                  </ul>
                </div>
                <div class="header__top__right__auth">
                  <a v-if="user.fullname===undefined" href="#/login"><i class="fa fa-user"></i> Connexion</a>
                  <a v-else href="#"><i class="fa fa-user"></i>{{ user.fullname }} [<span @click="logout" class="fa fa-close"></span>]</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="header__logo">
              <a href="./index.html"><img style="background-color:lightgray; width:150px; height:50px;" src="img/logoo.png" alt=""></a>
            </div>
          </div>
          <div class="col-lg-6">
            <nav class="header__menu">
              <ul>
                <li class="active"><a href="./index.html"><span class="fa fa-home"></span>Acceuil</a></li>
                <li><a href="#"><span class="fa fa-shopping-bag"></span> Achat</a></li>
                <li><a href="#"><span class="fa fa-phone"></span> Contact</a></li>
              </ul>
            </nav>
          </div>
          <div class="col-lg-3">
            <div class="header__cart">
              <ul>
                <li><a href="#/cart"><i class="fa fa-shopping-bag"></i> <span>{{panier.length}}</span></a></li>
              </ul>
              <a href="#/cart"><div class="header__cart__price"><span>${{totalPanier}}</span></div></a>
              <div style="margin-left: 5px; border:1px solid black; padding:3px; border-radius:5px;" class="header__top__right__auth">
                <a v-if="user.fullname===undefined" href="#/login"><i class="fa fa-user"></i> Connexion</a>
                <a v-else href="#"><i class="fa fa-user"></i>{{ user.fullname }} [<span @click="logout" class="fa fa-close"></span>]</a>
              </div>
            </div>
          </div>
        </div>
        <div class="humberger__open">
          <i class="fa fa-bars"></i>
        </div>
      </div>
    </header>
    <!-- Header Section End -->

    <!-- Hero Section Begin -->
    <section class="hero">
      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="hero__categories">
              <div class="hero__categories__all">
                <i class="fa fa-bars"></i>
                <span>Catégories</span>
              </div>
              <ul>
                <li v-for="(categorie,index) in categories" :key="index"><a href="#" style="font-size: 13px;">{{categorie.title}}</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-9">
            <div class="hero__search">
              <div class="hero__search__form">
                <form action="#">
                  <div class="hero__search__categories">
                    Tous
                    <span class="arrow_carrot-down"></span>
                  </div>
                  <input type="text" placeholder="Que cherchez-vous?">
                  <button type="submit" class="site-btn">RECHERCHE</button>
                </form>
              </div>
              <div class="hero__search__phone">
                <div class="hero__search__phone__icon">
                  <i class="fa fa-phone"></i>
                </div>
                <div class="hero__search__phone__text">
                  <h5>+243810000000</h5>
                  <span>support 24/7</span>
                </div>
              </div>
            </div>
            <div class="hero__item set-bg" data-setbg="img/hero/banner-3.jpg" style="background-image: url('img/hero/banner-3.jpg'); background-size: auto; ">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Hero Section End -->

    <!-- Categories Section Begin -->
    <section class="categories">
      <div class="container">
        <div class="row">
          <div class="categories__slider owl-carousel">
            <div v-for="(categorie,index) in categories" :key="index" class="col-lg-3">
              <div class="categories__item set-bg" data-setbg="img/categories/cat-1.jpg">
                <h5><a href="#">{{categorie.title}}</a></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Categories Section End -->

    <!-- about !-->
    <section class="featured spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <h2>QUI SOMMES-NOUS ?</h2>
            </div>
          </div>
        </div>
        <div class="row featured__filter">
          <div class="product__details__tab__desc">
            <p style="border:1px solid lightgray; padding:10px; border-radius:5px; font-style: italic;">L'Économat des fonctionnaire et agents publics de l'État - EFAPE - c'est un prestataire privé de services à caractère commercial, mieux un partenaire d'appui aux catégories ci-devant, jouant un rôle d'apporteur d'affaires, et approchant l'Administration publique au secteur privé.
              Toutefois, il s'ouvre à d’autres administrations et/ou organisations établies en République démocratique du Congo.
              L'EFAPE, il ambitionne d'accompagner et d'assurer l'équilibre dans les ménages des fonctionnaires et agents publics de l'État en leur assurant en permanence les approvisionnements en denrées alimentaires et autres nécessités.
              Ainsi, par voie de conséquence, emmener les fonctionnaires et agents publics de l'État à se concentrer davantage aux tâches administratives pour plus de performances.
              Somme toute, l'EFAPE se propose de répondre de la façon la plus appropriée aux attentes desdits fonctionnaires et agents pour leurs ménages respectifs
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- about end !-->

    <!-- pack !-->
    <section class="featured spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <h2>Liboke Ya Fonctionnaire</h2>
            </div>
          </div>
        </div>
        <div class="row featured__filter">
          <div v-for="(pack,index) in productsPack" :key="index" class="col-md-6" style="margin-bottom: 5px;" >
            <div style="border:1px solid lightgray; height: 100%;">
              <div class="pull-left" style=" height: 100%; width:50%;">
                <a href="#" @click.prevent="addToCart(pack)" class="btn btn-success" style="margin: 2px;"><span class="fa fa-shopping-cart"></span></a>
                <img :src="pack.img" alt="" style="width:auto; height:auto; ">
              </div>
              <div class="pull-right" style="width:50%;">
                <p style="background-color: #28a745; font-weight: bold; color: white; text-align: center">{{pack.title}}</p>
                <span v-for="(product,index) in pack.products" :key="index" class="badge badge-danger" style=" margin: 2px;"><span class="fa fa-check" ></span> {{product}}</span>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
    <!-- pack !-->

    <!-- Featured Section Begin -->
    <section class="featured spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <h2>Nos produits</h2>
            </div>
            <div class="featured__controls">
              <ul>
                <li @click="viewProducts()" class="active" data-filter="*" style="cursor: pointer;">Tous</li>
                <li data-filter=".oranges" v-for="(categorie,index) in categories" :key="index" @click="viewProducts(categorie.title)" style="font-size: 12px;">{{categorie.title}}</li>
                <!--<li data-filter=".fresh-meat">Viandes</li>
                <li data-filter=".vegetables">Légumes</li>!-->
              </ul>
            </div>
          </div>
        </div>
        <div class="row featured__filter" v-if="allProducts.length>0">
          <div  v-for="(produit,index) in allProducts" :key="index" class="col-lg-3 col-md-4 col-sm-6 mix oranges fresh-meat">

            <div class="featured__item">
              <a href="#" @click.prevent="addToCart(produit)" class="" style=" background-color: forestgreen; padding: 10px; margin-bottom: 2px;"><span style="color: white;" class="fa fa-shopping-cart"></span></a>
              <div class="featured__item__pic set-bg" :data-setbg="produit.img">
                <img :src="produit.img" style="border:1px solid lightgray; border-radius:5px; width:100%; height:100%;" alt="">
                <ul class="featured__item__pic__hover">
                  <li><a href="#" @click.prevent="addToCart(produit)"><i class="fa fa-shopping-cart"></i></a></li>
                  <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                  <li><a href="#"><i class="fa fa-whatsapp"></i></a></li>

                </ul>
              </div>
              <div class="featured__item__text">
                <h6><a href="#">{{produit.title}}</a></h6>
                <h5>{{produit.devise}}{{produit.prix}}</h5>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-md-12" style=" min-height: 350px; display: flex; justify-content: center; align-content: center">
            <img src="/products/empty.gif" alt="" style="width:250px; height:250px; margin: auto">
          </div>
        </div>
      </div>
    </section>
    <!-- Featured Section End -->

    <!-- Banner Begin -->
    <div class="banner">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="banner__pic">
              <img src="img/banner/banner-3.jpg" alt="">
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="banner__pic">
              <img src="img/banner/banner-3.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner End -->

    <!-- Latest Product Section Begin -->
    <section class="latest-product spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="latest-product__text">
              <h4>Recents produit</h4>
              <div class="latest-product__slider owl-carousel">
                <div class="latest-prdouct__slider__item">
                  <a v-for="(produit,index) in allProducts" :key="index" href="#" class="latest-product__item">
                    <div v-if="index<3">
                      <div class="latest-product__item__pic">
                        <img :src="produit.img" alt="" style="width:100px; height:100px;">
                      </div>
                      <div class="latest-product__item__text">
                        <h6>{{produit.title}}</h6>
                        <span>{{produit.devise}}{{produit.prix}}</span>
                      </div>
                    </div>

                  </a>
                </div>
                <div class="latest-prdouct__slider__item">
                  <a v-for="(produit,index) in allProducts" :key="index" href="#" class="latest-product__item">
                    <div v-if="index<3">
                      <div class="latest-product__item__pic">
                        <img :src="produit.img" alt="" style="width:100px; height:100px;">
                      </div>
                      <div class="latest-product__item__text">
                        <h6>{{produit.title}}</h6>
                        <span>{{produit.devise}}{{produit.prix}}</span>
                      </div>
                    </div>

                  </a>
                </div>

              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="latest-product__text">
              <h4>Produits populaires</h4>
              <div class="latest-product__slider owl-carousel">
                <div class="latest-prdouct__slider__item">
                  <a v-for="(produit,index) in allProducts" :key="index" href="#" class="latest-product__item">
                    <div v-if="index<3">
                      <div class="latest-product__item__pic">
                        <img :src="produit.img" alt="" style="width:100px; height:100px;">
                      </div>
                      <div class="latest-product__item__text">
                        <h6>{{produit.title}}</h6>
                        <span>{{produit.devise}}{{produit.prix}}</span>
                      </div>
                    </div>

                  </a>
                </div>
                <div class="latest-prdouct__slider__item">
                  <a v-for="(produit,index) in allProducts" :key="index" href="#" class="latest-product__item">
                    <div v-if="index<3">
                      <div class="latest-product__item__pic">
                        <img :src="produit.img" alt="" style="width:100px; height:100px;">
                      </div>
                      <div class="latest-product__item__text">
                        <h6>{{produit.title}}</h6>
                        <span>{{produit.devise}}{{produit.prix}}</span>
                      </div>
                    </div>

                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="latest-product__text">
              <h4>Récommendations</h4>
              <div class="latest-product__slider owl-carousel">
                <div class="latest-prdouct__slider__item">
                  <a v-for="(produit,index) in allProducts" :key="index" href="#" class="latest-product__item">
                    <div v-if="index<3">
                      <div class="latest-product__item__pic">
                        <img :src="produit.img" alt="" style="width:100px; height:100px;">

                      </div>
                      <div class="latest-product__item__text">
                        <h6>{{produit.title}}</h6>
                        <span>{{produit.devise}}{{produit.prix}}</span>
                      </div>
                    </div>

                  </a>
                </div>
                <div class="latest-prdouct__slider__item">
                  <a v-for="(produit,index) in allProducts" :key="index" href="#" class="latest-product__item">
                    <div v-if="index<3">
                      <div class="latest-product__item__pic">
                        <img :src="produit.img" alt="" style="width:100px; height:100px;">
                      </div>
                      <div class="latest-product__item__text">
                        <h6>{{produit.title}}</h6>
                        <span>{{produit.devise}}{{produit.prix}}</span>
                      </div>
                    </div>

                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Latest Product Section End -->

    <!-- Footer Section Begin -->
    <footer class="footer spad">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="footer__about">
              <div class="footer__about__logo">
                <a href="./index.html"><img style="background-color: lightgray; width:150px; height:50px;" src="img/logo0.png" alt=""></a>
              </div>
              <ul>
                <li><span class="fa fa-location-arrow"></span> Address: kinshasa gombe</li>
                <li><span class="fa fa-phone"></span> Phone: +243810000000</li>
                <li><span class="fa fa-envelope-o"></span> Email: </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <div class="footer__widget">
              <h6>Abonnez-vous</h6>
              <p>Soyez notifié des nouvelles offres</p>
              <form action="#">
                <input type="text" placeholder="Entrez votre email..">
                <button type="submit" class="site-btn">S'abonner</button>
              </form>
              <div class="footer__widget__social">
                <a href="#"><i class="fa fa-facebook"></i></a>
                <a href="#"><i class="fa fa-whatsapp"></i></a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer Section End -->

  </div>
</template>

<script>
export default {
  name: "Home",
  data:function(){
    return {
      allProducts:[],
      filteredProducts:[],
      recentProducts:[],
    }
  },
  computed:{
    user(){
      return this.$store.getters.getUser;
    },
    categories(){
      //return this.$store.state.categories;
      return this.$store.state.categories;
    },
    panier(){
      return this.$store.state.panier;
      //return this.$store.getters.getPanier;
      //return {};
    },
    totalPanier(){
      return this.$store.getters.getTotalCart;
    },
    productsPack(){
      return this.$store.state.productsPack;
    }
  },
  methods:{
    async logout()
    {
      await this.$store.dispatch("logout");
      this.$router.go("/");
    },
    async addToCart(produit){
      await this.$store.dispatch("addToCart",produit);
      this.$toast.open({
        message:"ajouté au panier",
        type:'success',
        duration:1000,
      });
    },
    viewProducts(categorie=null)
    {
      console.log("view now"); console.log(categorie);
      if(categorie!==null)
      {
        categorie=categorie.toLocaleLowerCase();
      }
      var produits=[];

      for(var i=0; i<this.categories.length; i++)
      {
        this.categories[i].title=this.categories[i].title.toLowerCase();
        //console.log(this.categories[i].title);
        if(categorie===null)
        {
          try
          {
            produits.push(...this.categories[i].produits);
            // eslint-disable-next-line no-empty
          }catch (e) {
          }
        }
        else if(categorie.includes(this.categories[i].title))
        {
          try
          {
            produits.push(...this.categories[i].produits);
            // eslint-disable-next-line no-empty
          }catch (e) {
          }
        }
        // eslint-disable-next-line no-empty
        else{}

      }

      this.allProducts=this.shuffleArray(produits);
      console.log(this.allProducts.length);
    },
    shuffleArray(array)
    {
      for (var i = array.length - 1; i > 0; i--)
      {

        // Generate random number
        var j = Math.floor(Math.random() * (i + 1));

        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }

      return array;
    },
    filterProducts()
    {
      var prod=this.shuffleArray(this.allProducts);

      this.filteredProducts[0]=prod[0];
      this.filteredProducts[1]=prod[1];
      this.filteredProducts[2]=prod[2];

      return this.filteredProducts;
    }

  },
  mounted()
  {
    this.viewProducts();
  }
}
</script>

<style scoped>

</style>