import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router/index";
import storeData from "./store/store.js";
import axios from "axios";

Vue.config.productionTip = false;

import PortalVue from "portal-vue";
Vue.use(PortalVue);
Vue.filter("sortlength", function(text, length, suffix) {
  var sorted = text.length > length ? text.substring(0, length) + suffix : text;
  return sorted;
});
Vue.filter("capitalize", function(text) {
  return text[0].toUpperCase() + text.slice(1);
});

Vue.use(Vuex);

//axios instance
import states from "./store/config/states";
var instance = axios.create({
  baseURL: states.baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});
Vue.prototype.$axios = instance;
import $ from "jquery";
var modal = function(key) {
  if (key === "show") {
    $(".trigger").click();
  }
  if (key === "hide") {
    $(".exit").click();
  }
};
Vue.prototype.$otpModal = modal;

Vue.component("Header",require("@/sections/Header").default);

Vue.component("InputWidget",require("@/widgets/InputWidget").default);

Vue.component("Breadcrumb",require("@/widgets/Breadcrumb").default);

//support vueSweetalert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

//support vueToast
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast);
// Dismiss all opened toast immediately
Vue.$toast.clear();

const store = new Vuex.Store(storeData);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
