<template>
  <div>
    <Header/>
    <!-- Breadcrumb Section Begin -->
    <Breadcrumb title="Panier"/>

    <!-- Shoping Cart Section Begin -->
    <div class="row">
      <div class="col-md-12">
        <section class="shoping-cart spad">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="shoping__cart__table">
                  <table>
                    <thead>
                    <tr>
                      <th class="shoping__product">Produit</th>
                      <th>Prix</th>
                      <th>Quantité</th>
                      <th>Total</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(produit,index) in panier" :key="index">
                      <td class="shoping__cart__item">
                        <img :src="produit.img" alt="" style="width:100px; height:100px; border:1px solid lightgray; border-radius:5px;">
                        <h5>{{ produit.title }}</h5>
                      </td>
                      <td class="shoping__cart__price">
                        ${{ produit.prix }}
                      </td>
                      <td class="shoping__cart__quantity">
                        <div class="quantity">
                          <div class="pro-qty">
                            <input style="display: none;" type="text" :value="(produit.qte===undefined)? produit.qte=1 : produit.qte">
                            <input type="text" v-model="produit.qte" @keyup="updateProductQty(produit)">
                          </div>
                        </div>
                      </td>
                      <td class="shoping__cart__total">
                        ${{(produit.prixTotal===undefined)? produit.prix : produit.prixTotal }}
                      </td>
                      <td class="shoping__cart__item__close">
                        <span class="icon_close" @click.p.prevent="removeFromCart(produit)"></span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row" style="justify-content: center;">
              <div class="col-lg-6">
                <div class="shoping__checkout">
                  <h5>Panier Total</h5>
                  <ul>
                    <li>Sous total <span>${{sousTotal}}</span></li>
                    <li>Livraison <span>${{deliveryCost}}</span></li>
                    <li>Total <span>${{ getTotal() }}</span></li>
                  </ul>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="payment_info modules__content">
                        <div v-if="wantToPay">
                          <p class="subtitle text-danger" style="font-weight: bold">
                            <span class="fa fa-money"></span> Choisissez votre moyen de
                            paiement
                          </p>

                          <div class="row" style="margin-bottom: 10px;">
                            <div class="col-lg-6 col-md-4 mb-2 mb-lg-0" >
                              <a href="#!" class="card text-center smooth-shadow-sm" :class="payment.payWithMobile ? 'border-primary border-2' : ''
                              " @click.prevent="togglePaymentMethod">
                                <div class="card-body p-4 px-4 d-flex align-content-center align-items-center" style="height:100px;">
                                  <div class="icon-shape bg-light-pink rounded-circle icon-xl">
                                    <img src="assets/images/icon/icon-4.svg" alt="document title" />
                                  </div>
                                  <h4 class="mb-0" style="font-size: 14px;">Mobile Money</h4>
                                </div>
                              </a>
                            </div>
                            <div class="col-lg-6 col-md-4 mb-2 mb-lg-0">
                              <a href="#!" class="card text-center smooth-shadow-sm" :class="!payment.payWithMobile ? 'border-primary border-2' : ''
                              " @click.prevent="togglePaymentMethod">
                                <div class="card-body p-4 px-4 d-flex align-content-center align-items-center" style="height:100px;">
                                  <div class="icon-shape bg-light-pink rounded-circle icon-xl">
                                    <img src="assets/images/svg/credit-card.svg" alt="document title" />
                                  </div>
                                  <h4 class="mb-0" style="font-size: 14px;">Carte Visa/Master</h4>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="wantToPay" class="row mt-3 p-2">
                    <div class="col-md-12 ">
                      <div v-if="payment.payWithMobile">
                        <div>
                          <label style="font-size: 15px;" for="card_number" class="form-label">* Numéro de téléphone (Mpesa, Orange Money, Airtel
                            Money, Afrimoney)</label>
                          <input id="card_number" type="text" class="form-control" placeholder="Saisissez le numéro de téléphone"
                                 v-model="payment.telephone" />
                        </div>
                        <button @click.prevent="pay" type="submit" class="btn btn-success btn-lg w-100 mt-3"
                                :class="payLoading ? 'disabled' : ''">
                          <span v-if="payLoading" class="spinner-border spinner-border-sm"></span>
                          <i v-else class="fa fa-lock"></i> Payer {{getTotal()}}$
                        </button>
                      </div>
                      <div v-else>
                        <form action="https://beta-cardpayment.flexpay.cd/v1/pay" method="post" id="card_form">
                          <div class="mb-2 d-none">
                            <input type="text" name="authorization" class="form-control"
                                   :value="cardTransaction.authorization" />
                          </div>
                          <div class="mb-2 d-none">
                            <input type="text" name="merchant" class="form-control" :value="cardTransaction.merchant" />
                          </div>
                          <div class="mb-2 d-none">
                            <input type="text" name="reference" class="form-control" :value="cardTransaction.reference" />
                          </div>
                          <div class="mb-2 d-none">
                            <input type="text" name="amount" class="form-control" :value="getTotal()" />
                          </div>
                          <div class="mb-2 d-none">
                            <input class="form-control" type="text" name="currency" :value="cardTransaction.currency" />
                          </div>
                          <div class="mb-2 d-none">
                            <input class="form-control" type="text" name="description"
                                   :value="cardTransaction.description" />
                          </div>
                          <div class="mb-2 d-none">
                            <input class="form-control" type="text" name="callback_url"
                                   :value="cardTransaction.callback_url" />
                          </div>
                          <div class="mb-2 d-none">
                            <input class="form-control" type="text" name="approve_url"
                                   :value="cardTransaction.approve_url" />
                          </div>
                          <div class="mb-2 d-none">
                            <input class="form-control" type="text" name="cancel_url" :value="cardTransaction.cancel_url" />
                          </div>
                          <div class="mb-2 d-none">
                            <input class="form-control" type="text" name="decline_url"
                                   :value="cardTransaction.decline_url" />
                          </div>

                          <button @click="pay()" type="submit" class="btn btn-success btn-lg w-100 mt-3"
                                  :class="payLoading ? 'disabled' : ''">
                            <span v-if="payLoading" class="spinner-border spinner-border-sm"></span>
                            <i v-else class="fa fa-lock"></i> Payer {{getTotal()}}$
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>

                  <a v-if="user.fullname!==undefined" href="#" class="primary-btn"><span class="fa fa-check"></span> CONFIRMER LA COMMANDE</a>
                  <a v-else href="#/login" class="primary-btn"><span class="fa fa-user"></span> CONNECTEZ-VOUS POUR CONFIMER</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Shoping Cart Section End -->

    <!-- Breadcrumb Section End -->
  </div>
</template>

<script>
export default {
  name: "Cart",
  data(){
    return {
      wantToPay: true,
      diligenceId: 0,
      payment: { payWithMobile: true, telephone: "", montant: 10, devise: "USD" },
      url:"http://economatfape.com",
      cardTransaction: {
        authorization:"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJcL2xvZ2luIiwicm9sZXMiOlsiTUVSQ0hBTlQiXSwiZXhwIjoxNzY1MzgxMjExLCJzdWIiOiI5MTYxNzc4YWMzMDA1YmU4Yzk2OTYxMGY0MGJmZDAyYyJ9.U2w-jMNYn1ixz3Xl4HlB2o9GR4to0T8AEwQTVXRuWY8",
        reference:"asasa",
        merchant:"CBP",
        description:"Paiement commande ECONOMAT",
        approve_url:"http://economatfape.com",
        callback_url:"http://economatfape.com",
        cancel_url:"http://economatfape.com",
        decline_url:"http://economatfape.com",
        currency:"USD"
      },
      payLoading: false,
    };
  },
  computed:{
    user(){
      return this.$store.getters.getUser;
    },
    panier(){
      return this.$store.state.panier;
      //return this.$store.getters.getPanier;
    },
    sousTotal(){
      return this.getSousTotal();
    },
    deliveryCost(){
      return this.getDeliveryCost();
    },
  },
  methods:{
    togglePaymentMethod() {
      if (this.payment.payWithMobile) {
        this.payment.payWithMobile = false;
      } else {
        this.payment.payWithMobile = true;
      }
    },
    pay(option=null) {
      if(!this.payment.payWithMobile)
      {
        this.payLoading=true;
        setTimeout(() => {
          document.getElementById("card_form").submit();
          //this.$emit("loading", false);
        }, 1000);
        return;
      }
      var formData = new FormData();
      if (this.payment.payWithMobile) {
        if (this.payment.telephone.length < 10) {
          this.displayMsg("Numéro de téléphone incorrect.");
          return false;
        }
        formData.append("telephone", this.payment.telephone);
      }
      this.payLoading = true;

      //formData.append("client_id", this.client.client_id);
      //formData.append("diligence_id", this.diligenceId);
      formData.append("user_id",1);
      formData.append("marchand_categorie_id",1);
      var method = this.payment.payWithMobile ? "mobile" : "card";
      formData.append("method", method);
      formData.append("montant", this.getTotal());
      formData.append("devise", this.payment.devise);

      /**
       * Envoyer la requete vers le serveur.
       */
      this.$store.dispatch("payer", formData).then((res) => {
        console.log(res);
        this.payLoading = false;
        if (res.error !== undefined) {
          this.displayMsg(res.error);
        } else if (res.reponse !== undefined) {
          if (res.reponse.status === "success") {
            if (this.payment.payWithMobile) {
              /**
               * Paiement par mobile money.
               /**
               *
               * Paiement transmis
               * */
              this.$swal({
                title: "Maintenan confirmez la transaction sur votre téléphone.",
                toast: true,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "Oui j'ai confirmé",
                cancelButtonText: "Non annuler",
              }).then((value) => {
                if (value.isConfirmed) {
                  /**
                   * Vérifier la transaction.
                   */
                  this.$swal(
                      {
                        title:"Votre paiement est passé en cours de vérification",
                        toast:true,
                        icon:"success",
                        timer:3000,
                        showConfirmButton:false,
                        showCancelButton:false
                      }
                  );
                  this.$store.dispatch("clearPanier")
                  setTimeout(() => {
                    this.$router.replace("/")
                  }, 3000);
                }
              });
            } else {
              /**
               * Paiement par carte.
               */
              this.cardTransaction = res.reponse.transaction_data;
              setTimeout(() => {
                document.getElementById("card_form").submit();
                //this.$emit("loading", false);
              }, 2000);
            }
          } else {
            this.displayMsg("Paiement non effectué.");
          }
        } else {
          this.displayMsg("Erreur! Réessayez plus tard.");
        }
      });
    },
    displayMsg(msg) {
      this.$swal({
        title: msg,
        toast: true,
        timer: 3000,
        icon: "error",
        showConfirmButton: false,
      });
    },
    getSousTotal(){
      var sousTotal=0;

      for(var i=0; i<this.panier.length; i++)
      {
        sousTotal+=(this.panier[i].prixTotal===undefined)? this.panier[i].prix : this.panier[i].prixTotal ;
      }

      return sousTotal;
    },
    getDeliveryCost(){
      return 2;
    },
    getTotal(){
      return this.sousTotal+this.deliveryCost;
    },
    removeFromCart(produit)
    {
      this.$store.dispatch("removeFromCart",produit);
    },
    updateProductQty(produit)
    {
      console.log("prod qte:"+produit.qte);
      console.log("product:"+produit.prix);
      this.$store.dispatch("updatePanier",produit);
    }
  },
  mounted() {
    if(this.panier.length<1){
      this.$router.replace("/");
    }
  }
}
</script>

<style scoped>

</style>