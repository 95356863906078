import axios from "axios";
import checkConnection from "../../utils/data-connection";
//import { scroller } from "vue-scrollto/src/scrollTo";

const actions = {
  addToCart({commit},produit){

    commit("addToCart",produit);
  },
  removeFromCart({commit},produit)
  {
    commit("removeFromCart",produit);
  },
  registerUser({commit},user)
  {
    commit("setUsers",user);
    commit("setUser",user);
  },
  logout({commit},user)
  {
    commit("setUser",null);
  },
  login({commit},user)
  {
    commit("setUser",user);
  },
  updatePanier({commit,state},produit)
  {
    var panier=[];
    var panierData=state.panier;
    commit("clearPanier");
    for(var i=0; i<panierData.length; i++)
    {
      if(panierData[i].title===produit.title && panierData[i].prix===produit.prix)
      {console.log("found..:"+panierData[i].prix);
        /**
         * update prix.
         */
        if(panierData[i].prixTotal===undefined)
        {
          //state.panier[i].prixTotal
        }
        panierData[i].prixTotal=panierData[i].prix*produit.qte;
      }
      commit("addToCart",panierData[i]);
    }
  },

  payer({ state }, data) /** method pour payer le frais d'une diligence */ {
    return new Promise(function(resolve) {
      if (!checkConnection()) {
        alert("Vous êtes actuellement hors ligne.");
        resolve(false);
        return;
      }
      axios
          .post("https://backend.economatfape.com/users/abonnements/payer", data)
          .then(function(result) {
            //console.log(JSON.stringify(result));
            var data = result.data;
            resolve(data);
          });
    });
  },

  clearPanier({state,commit})
  {
    commit("clearPanier")
  }
};

export default actions;
